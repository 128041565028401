import React, { MouseEvent } from 'react';
import classNames from 'classnames';
import jump from 'jump.js';

interface NavLinkProps {
  children: JSX.Element[] | JSX.Element | string;
  closeNavMenu: React.Dispatch<React.SetStateAction<boolean>>;
  href: string;
  menu?: boolean;
  navLinkClass?: string;
  scrollOffset?: number | (() => number);
  target?: string;
}

const NavLink = ({children, closeNavMenu, scrollOffset = 0, href, menu = true, navLinkClass = "", target = "_self"}: NavLinkProps) => {
  const navLinkClickHandler = (event: MouseEvent<HTMLAnchorElement>): void => {
    event.preventDefault();
    closeNavMenu(false);
    target === '_self' ? jump(href, { duration: 400, offset: -(scrollOffset) }) : window.open(href, target);
  }

  return (
    <a className={classNames(`ayjee-link ${navLinkClass}`, {
      'ayjee-link--menu': menu
    })} href={href} onClick={navLinkClickHandler}>
      <span className="ayjee-link__content">{children}<span className="ayjee-link__period">.</span></span>
    </a>
  );
};

export { NavLink };
