import React from "react";
import { artwork } from "../types";

interface ArtworkProps {
  data: artwork
  modifierClassName?: string
}

export const Artwork = ({ data: { name, image, id, category }, modifierClassName }: ArtworkProps) => {
  return (
    <article className="ayjee-artwork-card">
      <div className={`ayjee-artwork-card__content ayjee-artwork-card__content--${id}`}>
        <img className={`ayjee-artwork-card__img ayjee-artwork-card__img--${id} ${modifierClassName}`} src={`./${image}`} alt={name} />
      </div>
    </article>
  )
};
