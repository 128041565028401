import React from "react";
import { company } from "../types";

interface CompanyLogoProps {
  data: company
  modifierClassName?: string
}

export const CompanyLogo = ({ data: { component }, modifierClassName }: CompanyLogoProps) => {
  return (
    <>
      {component}
    </>
  )
};
