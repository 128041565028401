import React from 'react';
import classNames from 'classnames';
import { TooltipIcon } from "./TooltipIcon";
import { TooltipText } from "./TooltipText";

interface TooltipProps {
  tooltipContentType: string;
}

const Tooltip = ({ tooltipContentType }: TooltipProps) => {
  return (
    <div className={classNames(`ayjee-tooltip`, { 'ayjee-tooltip--animate': tooltipContentType })}>
      <p className="ayjee-tooltip__content ayjee-margin-0">
        <TooltipIcon tooltipContentType={tooltipContentType} />
        <TooltipText tooltipContentType={tooltipContentType} />
      </p>
    </div>
  )
};

export { Tooltip };
