import React, { useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet";
import classNames from 'classnames';
import { art } from "../art";
import { Header } from "../components/Header";
import { Headshot } from "../components/Headshot";
import { CompanyGrid } from "../components/CompanyGrid";
import { Footer } from "../components/Footer";
import { Intro } from "../components/Intro";
import { ArtworkGrid } from "../components/ArtworkGrid";
import { TechGrid } from "../components/TechGrid";
import { TagList } from "../components/TagList";

const IndexPage = () => {
  const [isWorkContainerVisible, setIsWorkContainerVisible] = useState(false);
  const workContainerRef = useRef(null);

  const observerOptions = {
    root: null,
    rootMargin: "0px",
    threshold: 0.05
  }

  useEffect(() => {
    const observerCallback = (payload: object) => {
      if (payload[0].isIntersecting) {
        setIsWorkContainerVisible(payload[0].isIntersecting);
        observer.unobserve(workContainerRef.current);
      }
    }
    const observer = new IntersectionObserver(observerCallback, observerOptions);
    if (workContainerRef.current) {
      observer.observe(workContainerRef.current);
    }

    return () => {
      if (workContainerRef.current) {
        observer.unobserve(workContainerRef.current);
      }
    }
  }, []);

  return (
    <div className="ayjee-layout">
      <Helmet>
        <html lang="en" />
        <title>Adam Grossi - Front-End Developer and Designer</title>
        <meta charSet="utf-8" />
        <meta name="description" content='The portolio website of front-end developer and designer Adam "ayjee" Grossi.' />
        <meta name="keywords" content="Front-End Developer, Senior Designer, React Developer, Javascript Developer, UI Designer, UX Designer, Adam Grossi" />
        <link rel="icon" href="data:image/svg+xml,<svg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%220 0 100 100%22><text y=%22.9em%22 font-size=%2290%22>🍕</text></svg>"/>
        <link rel="canonical" href="https://ayjee.dev" />
        <meta name="theme-color" content="var(--ayjee-color-primary)" />
        <meta property="og:url" content="https://ayjee.dev"  />
        <meta property="og:title" content="Adam Grossi - Front-End Developer" />
        <meta property="og:description" content='The portolio website of front-end developer and designer Adam "ayjee" Grossi.' />
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-LMLEVKRP0T"></script>
        <script>
          {
            `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());

              gtag('config', 'G-LMLEVKRP0T');
            `
          }
        </script>
      </Helmet>

      <Header></Header>

      <main className="ayjee-main-content" id="main-content">
        <div className="ayjee-container ayjee-container--main-content">
          <Intro />
          <Headshot />
        </div>
      </main>

      <section id="work" className={classNames(`ayjee-work`, {
        'ayjee-work--animate': isWorkContainerVisible,
      })} ref={workContainerRef}>
        <div className="ayjee-container">
          <h2 className="ayjee-work__heading ayjee-opacity-0">Work<span className="ayjee-highlight">.</span></h2>
          <p className="ayjee__description ayjee-opacity-0">
            From Fortune 500 companies, to small mom and pop shops, I've had <br className="ayjee__description-break" /> the pleasure of working with some amazing people:
          </p>
        </div>
        <CompanyGrid />
        {/* <div className="ayjee-container">
          <p className="ayjee__description ayjee-opacity-0">
            while using some really fun technology
          </p>
          <TechGrid />
        </div>
        <div className="ayjee-container">
          <TagList />
        </div> */}
      </section>

      <section id="art" className="ayjee-artwork">
        <div className="ayjee-container">
          <h2 className="ayjee-art__heading">Play<span className="ayjee-highlight">.</span></h2>
          <p className="ayjee__description">A collection of some of the things I've made for fun in my free time, <br className="ayjee__description-break" /> to always keep the creative juices flowing.</p>
          <ArtworkGrid gridItems={art} />
        </div>
      </section>

      <Footer></Footer>
    </div>
  )
}

export default IndexPage;
