import React, { useState, useEffect } from "react";
import classNames from "classnames";
import IconSend from "../images/icon/send.svg";
import { NavLink } from "./NavLink";

const Header = () => {
  const [navMenuOpen, setNavMenuOpen] = useState(false);
  const [headerOffset, setHeaderOffset] = useState(0);

  useEffect(() => {
    const element = document.querySelector(`html`);
    const scrollLockSelector: string = `ayjee-overflow-hidden`;
    if (navMenuOpen) {
      element?.classList.add(scrollLockSelector);
    }
    return () => {
      element?.classList.remove(scrollLockSelector);
    };
  }, [navMenuOpen]);

  useEffect(() => {
    setHeaderOffset(parseInt(getComputedStyle(document.documentElement, null).getPropertyValue('--ayjee-header-height'), 10))
  }, [])

  return (
    <header className="ayjee-header">
      <div className="ayjee-container ayjee-container--header">
        <nav className="ayjee-nav">
          <div className="ayjee-nav__header">
            <h1 className="ayjee-nav__heading">
              <NavLink scrollOffset={headerOffset} href="#main-content" closeNavMenu={setNavMenuOpen} menu={false} navLinkClass={`ayjee-link--header`}>
                <span>AG</span>
                <span className="ayjee-nav__heading-period">.</span>
              </NavLink>
            </h1>
            <button className="ayjee-nav__toggle-cta" type="button" onClick={() => setNavMenuOpen((navMenuOpen) => !navMenuOpen)}>
              Menu
            </button>
          </div>
          <div className={classNames(`ayjee-nav__menu`, {
            'ayjee-nav__menu--visible': navMenuOpen
          })}>
            <NavLink scrollOffset={headerOffset} href="#work" closeNavMenu={setNavMenuOpen}>Work</NavLink>
            <NavLink scrollOffset={headerOffset} href="#art" closeNavMenu={setNavMenuOpen}>Play</NavLink>
            <NavLink scrollOffset={headerOffset} href="#main-content" closeNavMenu={setNavMenuOpen} navLinkClass="ayjee-link--email ayjee-letter-spacing-negative-half">
              <span aria-hidden="true"><IconSend /></span>
              <span>Email me</span>
            </NavLink>
          </div>
        </nav>
      </div>
    </header>
  )
}

export { Header };
