import React from "react";

interface HeadshotProps {
  modifierClass?: string;
}

const Headshot = ({ modifierClass }: HeadshotProps) => {
  return (
    <div className={`ayjee-headshot ${modifierClass}`}>
      <img className="ayjee-headshot__image" src="./headshot.png" alt="ayjee headshot" />
    </div>
  )
}

export { Headshot };
