import React from "react";
import { artwork } from "../types";
import { Artwork } from "./Artwork";

interface ArtworkGridProps {
  gridItems: artwork[]
}

export const ArtworkGrid = ({ gridItems }: ArtworkGridProps) => {
  return (
    <div className="ayjee-artwork-grid">
      {
        gridItems.map((gridItem) => (<Artwork data={gridItem} key={gridItem.id} />))
      }
    </div>
  )
}
