import React from "react";
import IconError from "../images/icon/error.svg";
import IconSuccess from "../images/icon/success.svg";

interface TooltipIconProps {
  tooltipContentType: string;
}

const TooltipIcon = ({ tooltipContentType }: TooltipIconProps) => {
  const tooltipIcon = () => {
    if (tooltipContentType === "success") {
      return <IconSuccess />
     } else if (tooltipContentType === "error") {
         return <IconError />
     } else {
       return ""
     }
  }
  return (
    <>
      { tooltipIcon() }
    </>
  )
};

export { TooltipIcon };
