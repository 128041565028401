import React from "react";
import { company } from "../types";
import { CompanyLogo } from "./CompanyLogo"
import LogoAir from "../images/company/air.svg";
import LogoAkai from "../images/company/akai.svg";
import LogoAlesis from "../images/company/alesis.svg";
import LogoAlto from "../images/company/alto.svg";
import LogoCiccone from "../images/company/ciccone-law.svg";
import LogoCharter from "../images/company/charter.svg";
import LogoClarivate from "../images/company/clarivate.svg";
import LogoIon from "../images/company/ion.svg";
import LogoKenzan from "../images/company/kenzan.svg";
import LogoMaudio from "../images/company/m-audio.svg";
import LogoMixmeister from "../images/company/mixmeister.svg";
import LogoNumark from "../images/company/numark.svg";
import LogoSonivox from "../images/company/sonivox.svg";
import LogoSpectrum from "../images/company/spectrum.svg";
import LogoThomson from "../images/company/thomson-reuters.svg";
import LogoWealthbox from "../images/company/wealthbox.svg";

const companies: company[] = [
  {
    "id": "air",
    "name": "AIR Music Technology",
    "component": <LogoAir />,
    "industry": ["Music Production", "Music Software"],
    "url": "https://airmusictech.com/",
    "tags": [
      "Graphic Design", "UI Design", "UX Design", "Marketing", "Software Engineering"
    ]
  },
  {
    "id": "akai",
    "name": "Akai Professional",
    "component": <LogoAkai />,
    "industry": ["Electronic Music Equipment", "Music Production", "Music Software"],
    "url": "https://alesis.com/",
    "tags": [
      "Graphic Design", "UI Design", "UX Design", "Marketing", "Software Engineering"
    ]
  },
  {
    "id": "alesis",
    "name": "Alesis",
    "component": <LogoAlesis />,
    "industry": ["Electronic Music Equipment", "Music Production"],
    "url": "https://alesis.com/",
    "tags": [
      "Graphic Design", "UI Design", "UX Design", "Marketing", "Software Engineering"
    ]
  },
  {
    "id": "alto",
    "name": "Alto Professional",
    "component": <LogoAlto />,
    "industry": ["Electronic Music Equipment", "Live Sound Engineering"],
    "url": "https://altoprofessional.com/",
    "tags": [
      "Graphic Design", "UI Design", "UX Design", "Marketing", "Software Engineering"
    ]
  },
  {
    "id": "ciccone-law",
    "name": "Ciccone Law",
    "component": <LogoCiccone />,
    "industry": ["Law", "Legal Representation"],
    "url": "http://cicconelaw.com/",
    "tags": [
      "Graphic Design", "UI Design", "UX Design", "Software Engineering", "Accessibility"
    ]
  },
  {
    "id": "charter",
    "name": "Charter",
    "component": <LogoCharter />,
    "industry": ["Mass Media", "Telecommunications"],
    "url": "https://corporate.charter.com/",
    "tags": [
      "Graphic Design", "UI Design", "UX Design", "Software Engineering", "Accessibility"
    ]
  },
  {
    "id": "clarivate",
    "name": "Clarivate",
    "component": <LogoClarivate />,
    "industry": ["Analytics", "Insight & Innovation"],
    "url": "https://clarivate.com/",
    "tags": [
      "UI Design", "UX Design", "Software Engineering", "Accessibility"
    ]
  },
  {
    "id": "ion",
    "name": "ION",
    "component": <LogoIon />,
    "industry": ["Consumer Electronics"],
    "url": "https://ionaudio.com/",
    "tags": [
      "Graphic Design", "UI Design", "UX Design", "Marketing", "Software Engineering"
    ]
  },
  {
    "id": "kenzan",
    "name": "Kenzan",
    "component": <LogoKenzan />,
    "industry": ["Digital Consulting", "Software Engineering"],
    "url": "https://kenzan.com/",
    "tags": [
      "Graphic Design", "UI Design", "UX Design", "Software Engineering", "Accessibility"
    ]
  },
  {
    "id": "m-audio",
    "name": "M-Audio",
    "component": <LogoMaudio />,
    "industry": ["Electronic Music Equipment", "Music Production", "Music Software"],
    "url": "https://m-audio.com/",
    "tags": [
      "Graphic Design", "UI Design", "UX Design", "Marketing", "Software Engineering"
    ]
  },
  {
    "id": "mixmeister",
    "name": "MixMeister",
    "component": <LogoMixmeister />,
    "industry": ["Music Production", "Music Software"],
    "url": "https://mixmeister.com/",
    "tags": [
      "Graphic Design", "UI Design", "UX Design", "Marketing", "Software Engineering"
    ]
  },
  {
    "id": "numark",
    "name": "Numark",
    "component": <LogoNumark />,
    "industry": ["DJ Equipment", "Music Software"],
    "url": "https://numark.com/",
    "tags": [
      "Graphic Design", "UI Design", "UX Design", "Marketing", "Software Engineering"
    ]
  },
  {
    "id": "sonivox",
    "name": "SONiVOX",
    "component": <LogoSonivox />,
    "industry": ["Music Production", "Music Software"],
    "url": "https://sonivox.com/",
    "tags": [
      "Graphic Design", "UI Design", "UX Design", "Marketing", "Software Engineering"
    ]
  },
  {
    "id": "spectrum",
    "name": "Spectrum",
    "component": <LogoSpectrum />,
    "industry": ["Cable TV", "Internet", "Telephone"],
    "url": "https://clarivate.com/",
    "tags": [
      "Graphic Design", "UI Design", "UX Design", "Software Engineering", "Accessibility"
    ]
  },
  {
    "id": "thomson-reuters",
    "name": "Thomson Reuters",
    "component": <LogoThomson />,
    "industry": ["Mass Media"],
    "url": "https://thomsonreuters.com/",
    "tags": [
      "UI Design", "UX Design", "Software Engineering", "Accessibility"
    ]
  },
  {
    "id": "wealthbox",
    "name": "Wealthbox",
    "component": <LogoWealthbox />,
    "industry": ["CRM", "Financial Technology"],
    "url": "https://wealthbox.com/",
    "tags": [
      "Graphic Design", "UI Design", "UX Design", "Software Engineering", "Accessibility"
    ]
  },
]

const CompanyGrid = () => {
  return (
    <div className="ayjee-company-logo-grid ayjee-opacity-0">
      {
        companies.map((gridItem) => <CompanyLogo data={gridItem} key={gridItem.id} />)
      }
    </div>
  )
}

export { CompanyGrid };
