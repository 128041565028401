import React, { useState, useEffect, useRef } from "react";
import IconClipboard from "../images/icon/clipboard.svg";
import IconSend from "../images/icon/send.svg";
import { Tooltip } from "./Tooltip";

const MainContentCta = () => {
  const [tooltipContentType, setTooltipContentType] = useState('');
  const emailCtaTextRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    const timeout = setTimeout(() => setTooltipContentType(''), 2000)
    return () => clearTimeout(timeout);
  }, [tooltipContentType, setTooltipContentType])

  const copyEmailHandler = () => {
    const emailText = emailCtaTextRef?.current?.innerText;
    if (emailText) {
      navigator.clipboard.writeText(emailText.toLowerCase())
        .then(() => {
          setTooltipContentType("success");
        })
        .catch(() => {
          setTooltipContentType("error");
        });
    }
  }

  return (
    <div className="ayjee-main-content-cta-container">
      <Tooltip tooltipContentType={tooltipContentType} />
      <div className="ayjee-main-content__cta-group">
        <a href="mailto:adam.m.grossi@gmail.com?subject=Hey%20ayjee!" target="_blank" className="ayjee-cta-send-email ayjee-letter-spacing-negative-half">
          <span className="ayjee-cta-send-email__content">
            <IconSend />
            <span className="ayjee-cta-send-email__text">Get in Touch.</span>
            <span className="ayjee-visually-hidden" ref={emailCtaTextRef}>adam.m.grossi@gmail.com</span>
          </span>
        </a>
        <button className="ayjee-cta-copy-email" type="button" disabled={tooltipContentType ? true : false} onClick={copyEmailHandler} aria-label="Copy email address to clipboard" title="Copy email address to clipboard">
          <span aria-hidden="true"><IconClipboard /></span>
        </button>
      </div>
    </div>
  )
}

export { MainContentCta };
