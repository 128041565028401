import React from "react";
import { MainContentCta } from "./MainContentCta";

const Intro = () => {
  return (
    <div className="ayjee-main-content__intro">
      <h2 className="ayjee-main-content__heading">Hey ✌🏻,&nbsp; I'm Adam Grossi. <br/>  My friends call me <span className="ayjee-highlight">ayjee</span>.</h2>
      <p className="ayjee-main-content__description">
        I'm an experienced <a href="https://linkedin.com/in/ayjee" target="_blank" className="ayjee-underline">Senior Designer</a> and <a href="https://linkedin.com/in/ayjee" target="_blank" className="ayjee-underline">Front-End Developer</a> (Deseloper)
        at <a href="https://wealthbox.com" target="_blank" className="ayjee-underline">Wealthbox CRM</a>. I have a passion for crafting thoughtful and accessible interfaces, experiences, and designs.
      </p>
      <MainContentCta />
    </div>
  )
}

export { Intro };
