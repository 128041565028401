import React from "react";
import IconGithub from "../images/icon/github.svg";
import IconLinkedin from "../images/icon/linkedin.svg";

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <footer id="contact" className="ayjee-footer ayjee-letter-spacing-negative-half">
      <div className="ayjee-container">
        <div className="ayjee-footer__content">
          <div className="ayjee-footer__text">
            <p className="ayjee-footer__copyright">Copyright Adam "ayjee" Grossi <sup>&copy;</sup> {year}.<br/>Designed with 🍕 in Little Rhody.</p>
            <p className="ayjee-footer__verse">Phillipians 4:6</p>
          </div>
          <div className="ayjee-footer__social">
            <a href="https://github.com/ayjee" target="_blank" className="ayjee-footer__social-link" aria-label="My github page">
              <span aria-hidden="true"><IconGithub /></span>
            </a>
            <a href="https://linkedin.com/in/ayjee" target="_blank" className="ayjee-footer__social-link" aria-label="My linkedin pages">
              <span aria-hidden="true"><IconLinkedin /></span>
            </a>
          </div>
        </div>
      </div>
    </footer>
  )
}

export { Footer };
