import { artwork } from "./types";

export const art: artwork[] = [
  {
    "id": "athletica-forte",
    "name": "Athletica Forte e Feroce",
    "image": "./art/athletica-forte.png",
    "category": "apparel",
  },
  {
    "id": "jesus",
    "name": "Jesus Low Poly",
    "image": "./art/jesus.jpg",
    "category": "print",
  },
  {
    "id": "church",
    "name": "Duomo di Sant’Andrea",
    "image": "./art/church.png",
    "category": "print",
  },
  {
    "id": "sardine",
    "name": "European Sardine",
    "image": "./art/sardine.jpg",
    "category": "print",
  },
  {
    "id": "dj-controller",
    "name": "DJ Controller",
    "image": "./art/dj-controller.jpg",
    "category": "print",
  },
  {
    "id": "love-is-still-the-answer",
    "name": "Love Is Still The Answer",
    "image": "./art/love-is-still-the-answer.png",
    "category": "print",
  },
  {
    "id": "flour-water-sea-salt-yeast",
    "name": "Pizza Dough",
    "image": "./art/flour-water-sea-salt-yeast.png",
    "category": "apparel",
  },
  {
    "id": "athletica-victory",
    "name": "Athletica Victory Loves Company",
    "image": "./art/athletica-victory.png",
    "category": "apparel",
  },
  {
    "id": "when-words-fail-music-speaks",
    "name": "When Words Fail Music Speaks",
    "image": "./art/when-words-fail-music-speaks.png",
    "category": "print",
  },
  {
    "id": "gabagool",
    "name": "Gabagool",
    "image": "./art/gabagool.png",
    "category": "apparel",
  },
  {
    "id": "midi-controller",
    "name": "MIDI Controller",
    "image": "./art/midi-controller.png",
    "category": "print",
  },
  {
    "id": "grandpa",
    "name": "WW2 Purple Heart Recipient",
    "image": "./art/grandpa.jpg",
    "category": "print",
  },
  {
    "id": "athletica-remarkable-mint",
    "name": "Athletica Remarkable",
    "image": "./art/athletica-remarkable-mint.png",
    "category": "apparel",
  },
  {
    "id": "athletica-never-sleep",
    "name": "Athletica Never Sleep",
    "image": "./art/athletica-never-sleep.png",
    "category": "apparel",
  },
  {
    "id": "macaroni-is-my-homie",
    "name": "Macaroni Is My Homie",
    "image": "./art/macaroni-is-my-homie.jpg",
    "category": "print",
  },
  {
    "id": "in-vino-veritas",
    "name": "In Vino Veritas",
    "image": "./art/in-vino-veritas.png",
    "category": "print",
  },
]
