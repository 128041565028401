import React from "react";

interface TooltipTextProps {
  tooltipContentType: string;
}

const TooltipText = ({ tooltipContentType }: TooltipTextProps) => {
  const tooltipText = () => {
    if (tooltipContentType === "success") {
      return "Copied email address to clipboard."
     } else if (tooltipContentType === "error") {
         return "Unable to copy email address to clipboard."
     } else {
       return ""
     }
  }

  return (
    <>
      { tooltipText() }
    </>
  )
};

export { TooltipText };
